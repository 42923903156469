*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Maison Neue", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.loader-parent {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loader-svg {
  height: 100px;
  width: 100px;
  margin: auto;
}

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background: whitesmoke;
}
.App::-webkit-scrollbar {
  display: none;
}

.cover {
  height: 12rem;
  width: 100%;
}
.cover img {
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.cover .curve {
  display: none;
}
.content {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
}
.content .vcard-button {
  width: 15%;
  height: 40px;
  display: flex;
  bottom: 3rem;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;
  outline: none;
  border: 1px solid #333;
  border-radius: 20px;
  text-transform: uppercase;
  cursor: pointer;
  gap: 0.6rem;
  color: black;
  position: fixed;
}
.content .vcard-mobile {
  display: none;
}
.content .vcard-button:hover {
  background: #333;
  color: #fff;
}
.content .profile {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  border: 15px solid whitesmoke;
  position: relative;
  top: -4.5rem;
  object-fit: cover;
  animation: content-img 0.8s 0.2s backwards;
  margin: 0;
}
@keyframes content-img {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1);
  }
}
.content .details {
  margin-top: -4rem;
}
.content .details h1 {
  font-size: 30px;
  text-align: center;
  animation: animate-name 0.3s 0.5s backwards;
  color: black;
}
@keyframes animate-name {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.content .details h4 {
  font-weight: normal;
  width: 30rem;
  text-align: center;
  margin: 1% 0;
  opacity: 0;
  animation: animate-social 0.4s 0.9s forwards;
  color: black;
}

.content .social-icon {
  display: flex;
  margin-top: 1%;
  gap: 1rem;
  cursor: pointer;
  animation: animate-social 0.3s 0.7s backwards;
}
@keyframes animate-social {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  95% {
    transform: scale(1.1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.content .social-btn {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  gap: 2rem;
  margin-bottom: 10%;
}
.content .social-btn button {
  border: 0;
  min-width: 100%;
  margin: auto;
  min-height: 55px;
  font-size: 17px;
  font-weight: bold;
  background-color: white;
  line-height: 18px;
  border-radius: 2px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.content .social-btn button:hover {
  background-color: #f5f5f5;
  box-shadow: 0px 6px 28px rgba(0, 0, 0, 0.12);
  transform: scale(1.05);
  border: 1px solid #f5f5f5;
}

.content .social-btn button a {
  display: flex;
  text-align: center;
  align-items: center;
  padding-left: 1%;
  color: black;
  text-decoration: none;
  gap: 0.4rem;
  width: 100%;
}
.content .social-btn button a img {
  width: 10%;
  height: 10%;
  box-shadow: 0px 6px 28px rgba(0, 0, 0, 0.12);
}
.content .social-btn button span {
  color: black;
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .content {
    padding: 0% 5%;
    z-index: 2;
  }
  .content .vcard-button {
    display: none;
  }
  .content .vcard-mobile {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: none;
    bottom: 2rem;
    right: 1.5rem;
    cursor: pointer;
    color: white;
    position: fixed;
    background: rgb(3, 20, 85);
  }
}

@media screen and (max-width: 500px) {
  .cover {
    height: 10rem;
    animation: cover-photo 1s ease-in-out backwards;
  }
  @keyframes cover-photo {
    0% {
      padding-bottom: 100px;
      transform: scale(2);
      animation-timing-function: ease-out;
    }
    100% {
      padding-bottom: 0;
      transform: scale(1);
    }
  }
  .cover .curve {
    display: block;
    height: 6rem;
    width: 100%;
    background: whitesmoke;
    position: absolute;
    top: 7.2rem;
    border-radius: 50%;
    z-index: 1;
  }
  .content .details h1 {
    margin-top: -3rem;
  }
  .content .details h4 {
    width: 100%;
    margin: 8% 0;
  }
  .content .profile {
    top: -7.5rem;
  }
  .content .social-btn {
    margin-top: 10%;
    width: 100%;
    gap: 1rem;
  }
  .content .social-btn button {
    padding: 0;
    width: 100%;
  }
}

